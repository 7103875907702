// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/_hooks/useApp.ts"
);
import.meta.hot.lastModified = "1738902500000";
}
// REMIX HMR END

import { useEffect } from 'react';
import { useLoaderData, useRouteLoaderData } from '@remix-run/react';

import { showToast } from '~/lib/toasts';

/**
 * Hooks For fetching the Logged in user
 */
export const useUser = () => {
  const data = useRouteLoaderData('root') as any;
  return {
    user: data?.user ?? null,
    isLoggedIn: data?.isLoggedIn ?? false,
  };
};

/**
 * Hooks for Showing the Application
 * Toast Message
 */
export const useToast = () => {
  const { toastMessage } = useLoaderData() as any;

  useEffect(() => {
    if (!toastMessage) return;
    if (toastMessage?.type && toastMessage?.message)
      showToast(toastMessage?.message, toastMessage?.type);
  }, [toastMessage]);
};
